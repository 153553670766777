import {SET_NON_LOCATED_ORDERS} from "../actions/types";

const initialState = {
    nonLocatedOrders: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_NON_LOCATED_ORDERS:
            return {
                ...state,
                nonLocatedOrders: action.payload,
            };
        default:
            return state;
    }
}
