import qs from "qs";
import * as yup from "yup";
import {Formik} from "formik";
import {toast} from "react-toastify";
import LaddaButton from "react-ladda";
import React, {useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'
import FormReactSelect from "../../../components/FormReactSelect";
import {shipmentService} from "../../../services/ShipmentService";
import {Button, ButtonGroup, Card, CardBody, Col, Container, FormGroup, FormText, Input, Row} from "reactstrap";

const deliveryOptions = [
    {label: "Mail Room", value: "mail_room"},
    {label: "Reception", value: "reception"},
    {label: "Shop Assistant", value: "shop_assistant"},
    {label: "Neighbour", value: "neighbor"},
    {label: "Front Porch", value: "front_porch"},
    {label: "Rear Porch", value: "rear_porch"},
    {label: "Rear Garden", value: "garden"},
    {label: "Behind Wheelie Bin", value: "behind_wheelie_bin"},
    {label: "Shed Garden House", value: "shed_garden_house"},
    {label: "Letter Box", value: "letter_box"},
]

const initValues = {
    leave_place: "",
    recipient_signature: "",
    recipient_name: "",
    neighbor_house_number: "",
    neighbor_street: "",
}

const validationSchema = yup.object().shape({
    leave_place: yup.string().required("Delivery Option is required!"),
    recipient_signature: yup.string().required("Recipient signature is required!"),
    recipient_name: yup.string().when("leave_place", {
        is: leave_place => leave_place === 'reception' || leave_place === 'mail_room' || leave_place === 'shop_assistant' || leave_place === 'neighbor',
        then: yup.string().required("Recipient name is required!")
    }),
    neighbor_house_number: yup.string().when("leave_place", {
        is: leave_place => leave_place === 'neighbor',
        then: yup.string().required("Neighbour house no is required!")
    }),
    neighbor_street: yup.string().when("leave_place", {
        is: leave_place => leave_place === 'neighbor',
        then: yup.string()
    }),
})

const SafePlaceDeliveryConfirmation = () => {
    const {search} = useLocation();
    const navigate = useHistory();
    const params = qs.parse(search, {ignoreQueryPrefix: true});
    const [deliveryOption, setDeliveryOption] = useState(null);

    const canvas = useRef();

    const handleDeliveryOptions = (option) => setDeliveryOption(option);

    const handleFormSubmit = (values, {setSubmitting}) => {
        shipmentService.leavePlaceForNSPOrder(params.order_id, values, params.token).then(()=> {
            toast.success("Successfully submitted!");
            setSubmitting(false);
            window.location.href = `https://fastdespatch.com/track/${params.order_id}`;
        }).catch(err => {
            console.log(err);
            toast.error(err.response ? err.response.data.detail : "Action Failed!");
            setSubmitting(false);
        });
    }

    return (
        <Container className="mt-5">
            <Row className="align-items-center">
                <Col lg={10}>
                    <Card>
                        <Formik
                            initialValues={initValues}
                            onSubmit={handleFormSubmit}
                            validationSchema={validationSchema}
                        >
                            {({handleChange, handleSubmit, values, setFieldValue, errors, isSubmitting}) => (
                                <CardBody className="my-3">
                                    <h2 className="text-center">Safe Place Delivery Confirmation</h2>

                                    <div>
                                        <p>Hi {params && params.receiver_name},</p>
                                        <p>You have advised our driver that would like your shipment delivered in a safe
                                            place, thereby authorising a release of the shipment without a
                                            signature.</p>
                                        <p className="mt-4">Please select where you would like your
                                            order <strong>({params && params.order_id})</strong> to be delivered:</p>
                                        <Row>
                                            <div className="my-2 col-lg-6 mx-auto">
                                                <FormGroup>
                                                    <label htmlFor="option">Delivery Options</label>
                                                    <FormReactSelect
                                                        readOnly={true}
                                                        name="leave_place"
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        value={deliveryOption}
                                                        label="Delivery Options"
                                                        options={deliveryOptions}
                                                        onChange={(option) => {
                                                            handleDeliveryOptions(option);
                                                            setFieldValue('leave_place', option.value)
                                                        }}
                                                    />
                                                    {errors.leave_place && (
                                                        <FormText color="danger">
                                                            {errors.leave_place}
                                                        </FormText>
                                                    )}
                                                </FormGroup>

                                                {(values.leave_place === 'mail_room' || values.leave_place === 'reception' || values.leave_place === 'shop_assistant' || values.leave_place === 'neighbor') ? (
                                                    <FormGroup>
                                                        <label htmlFor="recipient_name">Receiver's Name</label>
                                                        <Input
                                                            id="recipient_name"
                                                            name="recipient_name"
                                                            onChange={handleChange}
                                                        />

                                                        {errors.recipient_name && (
                                                            <FormText color="danger">
                                                                {errors.recipient_name}
                                                            </FormText>
                                                        )}
                                                    </FormGroup>
                                                ) : null}


                                                {(values.leave_place === 'neighbor') && (
                                                    <>
                                                        <FormGroup>
                                                            <label htmlFor="neighbor_house_number">House Name/Number</label>
                                                            <Input
                                                                onChange={handleChange}
                                                                id="neighbor_house_number"
                                                                name="neighbor_house_number"
                                                            />

                                                            {errors.neighbor_house_number && (
                                                                <FormText color="danger">
                                                                    {errors.neighbor_house_number}
                                                                </FormText>
                                                            )}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <label htmlFor="neighbor_street">Street(if different)</label>
                                                            <Input
                                                                id="neighbor_street"
                                                                name="neighbor_street"
                                                                onChange={handleChange}
                                                            />
                                                        </FormGroup>
                                                    </>
                                                )}
                                            </div>
                                        </Row>
                                        <p>N.B.: you are therefore accepting responsibility for this shipment and this
                                            'instruction' will feature on the electronic Proof Of Delivery.</p>
                                        <p>Neither the seller nor the carrier shall be held liable for any potential
                                            subsequent loss.</p>
                                        <p>Delivery details can be found on our website: <a
                                            href="https://fastdespatch.com/track" target="_blank"
                                            rel="noopener noreferrer">https://fastdespatch.com/track</a></p>
                                        <p>Please sign in the box:</p>

                                        <div className="text-center">
                                            <div className="col-lg-6 mx-auto">
                                                <SignatureCanvas
                                                    name="recipient_signature"
                                                    ref={canvas}
                                                    onEnd={()=> {
                                                        setFieldValue("recipient_signature", canvas.current.getTrimmedCanvas().toDataURL("image/png"));
                                                    }}
                                                    canvasProps={{height: 200, className: 'border mt-3 w-100'}}
                                                />
                                                {errors.recipient_signature && (
                                                    <FormText color="danger">
                                                        {errors.recipient_signature}
                                                    </FormText>
                                                )}
                                            </div>
                                            <Button
                                                color="link"
                                                className="d-inline-block"
                                                onClick={()=> {
                                                    canvas.current.clear();
                                                    setFieldValue("recipient_signature", "")
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </div>

                                        <div className="text-center mt-4">
                                            <ButtonGroup>
                                                <LaddaButton
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                    loading={isSubmitting}
                                                    disabled={isSubmitting}
                                                    className="btn btn btn-primary btn-lg"
                                                >
                                                    Submit
                                                </LaddaButton>
                                                {/*<Button*/}
                                                {/*    color="danger"*/}
                                                {/*>*/}
                                                {/*    Cancel*/}
                                                {/*</Button>*/}
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            )}
                        </Formik>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SafePlaceDeliveryConfirmation;
