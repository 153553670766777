import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {shipmentService} from "../../../services/ShipmentService";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Loader from "react-loaders";

const ThirdPartyLocalPrint = () => {
    const params = useParams();
    const orderId = params.order_id;
    const [html, setHtml] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        shipmentService.thirdPartyOrderLabelPrintOnLocal(orderId, auth.token).then(res => {
            if (res) {
                setHtml(res.data.html_text);
                setLoading(false);
            }
        }).catch(err => {
            toast.error(err.response.data.detail, {position: "bottom-center"});
            console.log(err);
            setLoading(false);
        })
    }, []);

    if (isLoading) {
        return (
            <div style={{
                width: "100%",
                height: "100vh",
                display:"flex",
                alignItems:"center",
                justifyContent:"center"
            }}>
                <Loader type="ball-clip-rotate-multiple" active/>
            </div>
        );
    }

    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: html}}/>
        </div>
    );
};

export default ThirdPartyLocalPrint;
