import axios from "axios";

import { USER_LOADED, USER_LOADING, AUTH_ERROR, LOGIN_FAIL, LOGOUT_SUCCESS } from "./types";
import { returnErrors } from "./messages";

import Env from "../env";

export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });

  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  axios
    .get(`${Env.apiUrl}/api/auth/user`, config)
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: AUTH_ERROR });
    });
};

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  axios
    .post(`${Env.apiUrl}/api/auth/logout`, null, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const login = (email, password, deviceUuid, kwargs) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .post(`${Env.apiUrl}/api/auth/login`, { email, password, platform: "admin", device_token: deviceUuid || "" }, config)
    .then(async (res) => {
      if (res.status === 202) {
        if (kwargs && kwargs.onVerification) {
          kwargs.onVerification();
        }
      } else {
        if (kwargs && kwargs.onSuccess) {
          kwargs.onSuccess(res.data);
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        console.log("Status:", err.response);
        if (kwargs && kwargs.onFailure) {
          kwargs.onFailure(err);
        }
      }
      // dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    });
};

export const verifyToken = (email, otp_token, kwargs) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .post(`${Env.apiUrl}/api/auth/verify-token/`, { email, otp_token, device_token: "" }, config)
    .then(async (res) => {
      if (kwargs && kwargs.onSuccess) {
        kwargs.onSuccess(res.data);
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        console.log(err.response);
        if (kwargs && kwargs.onFailure) {
          kwargs.onFailure(err.response.data);
        }
      }
      // dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    });
};

export const resendVerificationCode = (email) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.post(`${Env.apiUrl}/api/auth/resend-verification-code/`, { email }, config);
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};
