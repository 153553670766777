import React, { Component } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "react-loaders";
import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, auth, roles, ...rest }) => {
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isLoading) {
          return (
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-rise" active/>
                </div>
              </div>
            </div>
          );
        } else if (!auth.isAuthenticated) {
          return <Redirect to="/auth/login" />;
        } else {
          if (roles.length === 0 || roles.find(role => role === auth.user.role))
            return <Component {...props} />;
          else {
            alert('You have no permission to access this page.');
            history.goBack();
          }
        }
      }}
    />
  );
};


PrivateRoute.defaultProps = {
  roles: [],
};

PrivateRoute.propTypes = {
  roles: PropTypes.array
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
