import {Route, Redirect, withRouter} from "react-router-dom";
import React, {Suspense, lazy, Fragment} from "react";
import Loader from "react-loaders";
import {connect} from "react-redux";

import PrivateRoute from "../../components/PrivateRoute";
import Error404 from "../../pages/Auth/Error404";

import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {UserRoleValues} from "../../constants";
import ThirdPartyLocalPrint from "../../pages/Orders/ThirdParties/LocalPrint";
import SafePlaceDeliveryConfirmation from "../../pages/Utilities/SafePlaceDeliveryConfirmation";

const DashboardPages = lazy(() => import("../../pages/Dashboard"));
const UserPages = lazy(() => import("../../pages/Users"));
const OrderPages = lazy(() => import("../../pages/Orders"));
const SortationDepotPages = lazy(() => import("../../pages/SortationDepot"));
const AuthPages = lazy(() => import("../../pages/Auth"));
const SchedulingPages = lazy(() => import("../../pages/Scheduling"));
const ReportPages = lazy(() => import("../../pages/Report"));
const StockPages = lazy(() => import("../../pages/Stock"));
const FinancePages = lazy(() => import("../../pages/Finance"));
const SettingPages = lazy(() => import("../../pages/Settings"));
const RoutingPages = lazy(() => import("../../pages/Routing"));
const HRPages = lazy(() => import("../../pages/HR"));
const UtilitiesPages = lazy(() => import("../../pages/Utilities"));

const defaultRedirect = (role) => {
    if (
        role === UserRoleValues.ADMIN ||
        role === UserRoleValues.SUPER_ADMIN ||
        role === UserRoleValues.DEPOT_MANAGER ||
        role === UserRoleValues.REGION_MANAGER
    ) {
        return <Redirect to="/dashboard"/>;
    } else if (role === UserRoleValues.HR_MANAGER) {
        return <Redirect to="/users/drivers"/>;
    } else if (role === UserRoleValues.SECURITY) {
        return <Redirect to="/finance/invoices"/>;
    } else if (role === UserRoleValues.CALL_CENTER_ASSISTANT) {
        return <Redirect to="/dashboard"/>;
    } else if (role === UserRoleValues.SORTATION_DEPOT_ASSISTANT) {
        return <Redirect to="/sortation-depots/pallets"/>;
    } else {
        alert("Wrong user role.");
        return null;
    }
};

const AppMain = ({auth}) => {
    return (
        <Fragment>
            {/* Users */}
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise" active/>
                            </div>
                        </div>
                    </div>
                }
            >
                <PrivateRoute exact path="/">
                    {auth.user && defaultRedirect(auth.user.role)}
                </PrivateRoute>
                <PrivateRoute
                    path="/dashboard"
                    component={DashboardPages}
                    roles={[
                        UserRoleValues.ADMIN,
                        UserRoleValues.SUPER_ADMIN,
                        UserRoleValues.REGION_MANAGER,
                        UserRoleValues.DEPOT_MANAGER,
                        UserRoleValues.CALL_CENTER_ASSISTANT,
                    ]}
                />
                <PrivateRoute
                    path="/users"
                    component={UserPages}
                    roles={[
                        UserRoleValues.ADMIN,
                        UserRoleValues.SUPER_ADMIN,
                        UserRoleValues.REGION_MANAGER,
                        UserRoleValues.DEPOT_MANAGER,
                        UserRoleValues.HR_MANAGER,
                        UserRoleValues.CALL_CENTER_ASSISTANT,
                    ]}
                />
                <PrivateRoute
                    path="/orders"
                    component={OrderPages}
                    roles={[
                        UserRoleValues.ADMIN,
                        UserRoleValues.SUPER_ADMIN,
                        UserRoleValues.REGION_MANAGER,
                        UserRoleValues.DEPOT_MANAGER,
                        UserRoleValues.CALL_CENTER_ASSISTANT,
                        UserRoleValues.SORTATION_DEPOT_ASSISTANT,
                    ]}
                />

                <PrivateRoute
                    path="/sortation-depots"
                    component={SortationDepotPages}
                    roles={[UserRoleValues.ADMIN, UserRoleValues.SUPER_ADMIN, UserRoleValues.SORTATION_DEPOT_ASSISTANT, UserRoleValues.CALL_CENTER_ASSISTANT]}
                />
                {/* <PrivateRoute
          path="/support"
          component={SupportPages}
          roles={[UserRoleValues.ADMIN, UserRoleValues.SUPER_ADMIN, UserRoleValues.CALL_CENTER_ASSISTANT]}
        /> */}
                <PrivateRoute
                    path="/scheduling"
                    component={SchedulingPages}
                    roles={[
                        UserRoleValues.ADMIN,
                        UserRoleValues.SUPER_ADMIN,
                        UserRoleValues.REGION_MANAGER,
                        UserRoleValues.DEPOT_MANAGER,
                        UserRoleValues.CALL_CENTER_ASSISTANT,
                    ]}
                />
                <PrivateRoute
                    path="/stock"
                    component={StockPages}
                    roles={[UserRoleValues.ADMIN, UserRoleValues.SUPER_ADMIN, UserRoleValues.REGION_MANAGER, UserRoleValues.DEPOT_MANAGER]}
                />
                <PrivateRoute
                    path="/finance"
                    component={FinancePages}
                    roles={[
                        UserRoleValues.ADMIN,
                        UserRoleValues.SECURITY,
                        UserRoleValues.SUPER_ADMIN,
                        UserRoleValues.DEPOT_MANAGER,
                        UserRoleValues.REGION_MANAGER,
                        UserRoleValues.CALL_CENTER_ASSISTANT
                    ]}
                />
                <PrivateRoute
                    path="/reports"
                    component={ReportPages}
                    roles={[
                        UserRoleValues.ADMIN,
                        UserRoleValues.SUPER_ADMIN,
                        UserRoleValues.DEPOT_MANAGER,
                        UserRoleValues.REGION_MANAGER,
                        UserRoleValues.CALL_CENTER_ASSISTANT,
                    ]}
                />
                <PrivateRoute
                    path="/routing"
                    component={RoutingPages}
                    roles={[
                        UserRoleValues.ADMIN,
                        UserRoleValues.SUPER_ADMIN,
                        UserRoleValues.REGION_MANAGER,
                        UserRoleValues.DEPOT_MANAGER,
                        UserRoleValues.SORTATION_DEPOT_ASSISTANT,
                        UserRoleValues.CALL_CENTER_ASSISTANT,
                    ]}
                />
                <PrivateRoute
                    path="/settings"
                    component={SettingPages}
                    roles={[UserRoleValues.ADMIN, UserRoleValues.SUPER_ADMIN]}
                />
                <PrivateRoute
                    path="/hr"
                    component={HRPages}
                    roles={[
                        UserRoleValues.ADMIN,
                        UserRoleValues.SUPER_ADMIN,
                        UserRoleValues.HR_MANAGER
                    ]}
                />
                <PrivateRoute
                    path="/utilities"
                    component={UtilitiesPages}
                    roles={[UserRoleValues.ADMIN, UserRoleValues.SUPER_ADMIN]}
                />

                <Route path="/auth" component={AuthPages}/>
                <Route path="/error" component={Error404}/>
                <Route
                    component={ThirdPartyLocalPrint}
                    path="/third-parties/print-label/:order_id"
                />
                <Route path="/safe-place-delivery-confirmation" component={SafePlaceDeliveryConfirmation}/>
            </Suspense>

            <ToastContainer theme="colored" position="bottom-center"/>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(AppMain));
