// ---------------------- Auth types ----------------------
export const GET_ERRORS = 'GET_ERRORS'

export const USER_LOADING = "USER_LOADING"
export const USER_LOADED = "USER_LOADED"
export const AUTH_ERROR = "AUTH_ERROR"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'


// ---------------------- Notification types ----------------------
export const SET_NOTIFICATION = 'GET_NOTIFICATION';
export const LOADING_NOTIFICATION = 'LOADING_NOTIFICATION';


// ---------------------- Notification types ----------------------
export const SET_NON_LOCATED_ORDERS = 'SET_NON_LOCATED_ORDERS';
