import thunk from "redux-thunk";
import reducers from "../reducers";
import {composeWithDevTools} from "redux-devtools-extension";
import {createStore, combineReducers, applyMiddleware} from "redux";

const middlewares = [thunk];

export default function configureStore() {
    return createStore(
        combineReducers({
            ...reducers,
        }),
        {},
        composeWithDevTools(applyMiddleware(...middlewares))
    );
}
