import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    GET_ERRORS
} from "../actions/types";

const initialState = {
    token: localStorage.getItem("token"),
    deviceUuid: localStorage.getItem("deviceUuid"),
    isAuthenticated: null,
    isLoading: false,
    user: null,
    error: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload,
            };
        case LOGIN_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("deviceUuid", action.payload.device_uuid);
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isLoading: false,
                deviceUuid: action.payload.device_uuid
            };
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case AUTH_ERROR:
            localStorage.removeItem("token");
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false,
            };
        case GET_ERRORS:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}
