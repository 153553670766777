import Env from "env";
import moment from "moment-timezone";
import {consts} from "./constants";

/**
 * Remove Empty Value Keys From an Object
 * @param obj
 * @returns {*}
 */
export const removeEmptyValueKeys = (obj) => {
    const newObj = {...obj}

    for (const key in newObj) {
        if (newObj.hasOwnProperty(key)) {
            if (!newObj[key] && typeof newObj[key] !== 'boolean' || (Array.isArray(newObj[key]) && newObj[key].length === 0)) {
                delete newObj[key];
            }
        }
    }

    return newObj;
}

export function distance(lat1, lon1, lat2, lon2, unit = "K") {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    } else {
        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;
        const theta = lon1 - lon2;
        const radtheta = (Math.PI * theta) / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") {
            dist = dist * 1.609344;
        }
        if (unit === "N") {
            dist = dist * 0.8684;
        }
        return dist;
    }
}

export function objectToQueryString(obj) {
    if (!obj) return "";

    const str = [];
    for (const p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p] === null || obj[p] === undefined ? "" : obj[p]));
        }
    return str.join("&");
}

export function firstCharUppercase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatAddressString(address1, address2, city, state, postcode, country) {
    return `${address1}${address2 ? " " + address2 : ""}, ${city}, ${state ? state + ", " : ""}${postcode}, ${country}`;
}

export function getWeekDates(date) {
    const d = new Date(date.getTime());
    const first = d.getDate() - d.getDay(); // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6
    let dates = [];
    for (let i = first; i <= last; i++)
        dates.push(new Date(d.setDate(i)));
    return dates;
}

export function MEDIA_URL(url) {
    if (url.startsWith('http'))
        return url;
    else
        return `${Env.apiUrl}${url}`;
}

/**
 * Get Suffix of Ordinal Numbers
 * @param number
 * @returns {string|string}
 */
export const getOrdinalNoSuffix = (number) => {
    return ["st", "nd", "rd"][((number + 90) % 100 - 10) % 10 - 1] || "th";
}


/**
 * Format Date & Time (UK Timezone)
 * @param date
 * @returns {string}
 */
export const formatDateTime = (date) => {
    return moment(date).tz(consts.FDL_TIMEZONE).format('YYYY-MM-DD, H:mm:ss');
}

/**
 * Format Date (UK Timezone)
 * @param date
 * @returns {string}
 */
export const formatDate = (date) => {
    return moment(date).tz(consts.FDL_TIMEZONE).format('YYYY-MM-DD');
}

/***
 * Convert date from local machine to UTC
 * @param date
 * @returns {string}
 */
// export const convertDateLocalToUTC = (date) => {
//     const applyOffset = date.setTime(date.getTime() - date.getTimezoneOffset() * 60000);
//     const actualTime = new Date(applyOffset).toISOString().replace("Z", "");
//     const toTz = moment.tz(actualTime, consts.FDL_TIMEZONE).format();
//     return moment.utc(toTz).format();
// }

export function convertUTCToLocalDate(date) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
}

export function convertLocalToUTCDate(date) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
}

/**
 * Generate Random Color Code
 * @returns {string}
 */
export function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const validImageExtensions = ['image/jpg', 'image/gif', 'image/png', 'image/jpeg', 'image/svg', 'image/webp'];
