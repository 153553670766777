import Env from "../env";
import axios from "axios";
import moment from "moment";
import {RecipientTypeValues, PalletDirection, OrderStatusValues} from "../constants";
import {objectToQueryString} from "utils";

export const shipmentService = {
    getShipments,

    getOrders,
    getOrdersInRoute,
    fetchOrders,
    getOrder,
    updateOrder,
    getOrderSignatures,
    changeOrderStatus,
    changeOrderDepots,
    deleteOrder,
    getDepotDetails,
    generateLabelPdf,
    debriefOrder,
    debriefParcel,
    exportOrders,
    uploadOrderDeliveryPicture,
    changeOrderLogTime,

    updateOrderSenderDetails,
    updateOrderRecipientDetails,
    updateOrderParcels,
    updateOrderParcelStatuses,
    updateOrderOptions,

    deliverOrderManually,

    getReceivableOrders,
    getReceivableOrdersForSortation,
    getSortationDepotDeliveryPallets,
    getSortationDepotCollectionPallets,
    getParcelsForArrangingDeliveryPallets,
    getParcelsForArrangingCollectionPallets,
    getOrdersforRouting,
    getTodayDeliveries,
    fetchDepotStockParcels,
    destroyParcel,
    returnParcel,
    verifyScans,
    verifyPalletScan,
    doNotHold,

    submitDeliveryPalletScans,
    submitCollectionPalletScans,
    createEmptyDeliveryPallet,
    createEmptyCollectionPallet,
    deletePallet,
    getDeliveryPallets,
    fetchDeliveryPallets,
    fetchCollectionPallets,
    exportDeliveryPallets,

    getRoute,
    debriefRoute,

    receiveStock,
    checkParcel,
    receiveStockAtSortationDepot,
    receiveShipmentManually,

    createWork,
    createEmptyRoute,
    setRouteDepartureTime,
    getWork,
    submitAvilableDrivers,
    getDepotWorks,
    deleteRoute,
    makeRouteReady,
    updateRoute,
    getRoutes,
    fetchRoutes,
    assignDriverToRoute,
    generateRouteManifest,

    getDepotStock,
    getDepotStockItems,
    destroyDepotStock,
    returnDepotStock,
    setUsableDepotStock,
    getMainStockItems,
    getInTransitStockItems,
    getSortationDepotStockItems,
    getSortationDepotScans,
    exportSortationDepotScans,

    fetchCSVImports,
    fetchCustomDeclarations,

    getOrderNumForPreRouting,
    calculatePreroute,
    getPreroutes,

    getDepotCollectionOverview,

    getLatestOrderUpdates,
    getMonthlyOrderCount,
    getDeliveriesByDate,
    getThirdPartyDeliveriesByDate,
    getRecentTimeinTransit,
    getHoldingInDeliveryDepot,
    getTodayWorkForDashboard,
    getMissortedOrdersNum,
    exportRoutesSummary,

    fetchDeliveryAddresses,
    approveDeliveryAddress,
    changeOrderLocation,
    changeOrderDeliveryDate,
    getOrderStatusLogs,

    getAddressRequests,
    approveAddressRequest,
    setRequestedAddress,

    setDelayAdjustment,

    fetchOrderManifests,
    getOrderManifestDetails,
    deleteOrderManifest,

    fetchReturnOrders,
    finishReturnOrders,
    changeReturnOrderStatus,
    getNonLocatedOrdersNum,

    startRouteManually,
    fetchBulkLabels,
    fetchBulkStatus,
    fetchBulkStatusHistory,
    bulkStatusRevert,
    bulkStatusChange,
    regenerateBulkLabelPdf,

    orderKeepAtDepot,

    leavePlaceForNSPOrder,

    fetchAndRepoShipments,

    getOrderScanHistories,

    saveAMZLShipmentID,
    manualThirdPartyOrderCreate,
    thirdPartyOrderLabelPrintOnLocal,
    thirdPartyOrderLabelPrintOnDedicated,

    locateNonLocateOrders,

    fetchNLCollections,
    fetchOutstandingNLCollections,
    fetchNLCollectionsMapMarkers
};

//------------------------- Shipment APIs -----------------------------

function getShipments(token) {
    return axios.get(`${Env.apiUrl}/api/shipments/`, {headers: {Authorization: `Token ${token}`}});
}

//------------------------- Order APIs ------------------------------

function getOrders(query, token) {
    if (query != null) return axios.get(`${Env.apiUrl}/api/orders/?${query}`, {headers: {Authorization: `Token ${token}`}});
    else return axios.get(`${Env.apiUrl}/api/orders/`, {headers: {Authorization: `Token ${token}`}});
}

function getOrdersInRoute(routeId, token) {
    return axios.get(`${Env.apiUrl}/api/routes/${routeId}/orders/`, {headers: {Authorization: `Token ${token}`}});
}

function fetchOrders(query, payload, token) {
    return axios.post(`${Env.apiUrl}/api/orders/fetch/?${query}`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function exportOrders(query, payload, token) {
    return axios.post(`${Env.apiUrl}/api/orders/export-xlsx/?${query}`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
        responseType: "blob",
    });
}

function getOrder(id, token) {
    return axios.get(`${Env.apiUrl}/api/orders/${id}/`, {headers: {Authorization: `Token ${token}`}});
}

function getOrderSignatures(orderId, token) {
    return axios.get(`${Env.apiUrl}/api/orders/${orderId}/signatures/`, {
        headers: {Authorization: `Token ${token}`},
    });
}

function getOrderScanHistories(orderId, params, token) {
    return axios.get(`${Env.apiUrl}/api/orders/${orderId}/scan-histories/`, {
        headers: {Authorization: `Token ${token}`},
        params
    });
}

function updateOrder(data, orderId, token) {
    const payload = {
        sender: {
            contact_name: data.sender.contact_name.value,
            email: data.sender.email.value,
            phone_number: data.sender.phone_number.value || "",
            warehouse: data.sender.warehouse.value.id,
            company_name: data.sender.company_name.value,
            address: {
                address1: data.sender.address1.value,
                address2: data.sender.address2.value,
                address3: "",
                city: data.sender.city.value,
                state: data.sender.state.value,
                postcode: data.sender.postcode.value,
                country: data.sender.country.value,
            },
        },
        recipient: {
            type: data.recipient.type.value,
            contact_name: data.recipient.contact_name.value,
            company_name: data.recipient.type.value === RecipientTypeValues.BUSINESS ? data.recipient.company_name.value : null,
            phone_number: data.recipient.phone_number.value || "",
            email: data.recipient.email.value,
            address: {
                address1: data.recipient.address1.value,
                address2: data.recipient.address2.value,
                address3: "",
                city: data.recipient.city.value,
                state: data.recipient.state.value,
                postcode: data.recipient.postcode.value,
                country: data.recipient.country.value,
            },
        },
        // Options
        need_collection: data.options.need_collection,
        age_restricted: data.options.age_restricted,
        contains_liquid: data.options.contains_liquid,
        fragile: data.options.fragile,

        // Options
        reference: data.options.reference.value,
        delivery_notes: data.options.delivery_notes.value,

        use_one_time_password: data.options.use_one_time_password,
        value: data.options.value.value,

        cod: data.options.cod,
        cod_amount: data.options.cod_amount.value,
        cod_method: data.options.cod_method.value,

        // Import Options
        t_code: data.options.import_to_the_uk ? data.options.t_code.value : undefined,
        currency: data.options.import_to_the_uk ? data.options.currency.value : undefined,
        description: data.options.import_to_the_uk ? data.options.description.value : undefined,
        web_link: data.options.import_to_the_uk ? data.options.web_link.value : undefined,
    };

    return axios.patch(`${Env.apiUrl}/api/orders/${orderId}/`, payload, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function changeOrderStatus(status, orderId, token) {
    return axios.patch(
        `${Env.apiUrl}/api/orders/${orderId}/change-status/`,
        {status},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function changeOrderDepots(collectionDepotId, deliveryDepotId, orderId, token) {
    return axios.patch(
        `${Env.apiUrl}/api/orders/${orderId}/change-depots/`,
        {
            collection_depot: collectionDepotId,
            delivery_depot: deliveryDepotId,
        },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function verifyScans(parcelScans, destOrderStatus, token) {
    return axios.post(
        `${Env.apiUrl}/api/orders/verify-scans/`,
        {scans: parcelScans, dest_status: destOrderStatus},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function verifyPalletScan(scannedPalletId, depotId, token) {
    return axios.get(`${Env.apiUrl}/api/sortation-pallets/${scannedPalletId}/verify-for-reception/?depot=${depotId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function changeOrderLogTime(dt, logId, token) {
    return axios.post(
        `${Env.apiUrl}/api/order-logs/${logId}/change-time/`,
        {created_at: dt},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

// ------------------ Update Order --------------------
function uploadOrderDeliveryPicture(deliveryPicture, orderId, token) {
    let payload = new FormData();
    payload.append("leave_picture", deliveryPicture);
    return axios.post(`${Env.apiUrl}/api/orders/${orderId}/set-leave-picture/`, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
        },
    });
}

function updateOrderSenderDetails(data, orderId, token) {
    return axios.post(`${Env.apiUrl}/api/orders/${orderId}/sender-details/`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function updateOrderRecipientDetails(data, orderId, token) {
    return axios.post(`${Env.apiUrl}/api/orders/${orderId}/recipient-details/`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function updateOrderParcels(data, orderId, token) {
    return axios.post(`${Env.apiUrl}/api/orders/${orderId}/update-parcels/`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function updateOrderParcelStatuses(data, orderId, token) {
    return axios.post(`${Env.apiUrl}/api/orders/${orderId}/update-parcel-statuses/`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function updateOrderOptions(data, orderId, token) {
    return axios.post(`${Env.apiUrl}/api/orders/${orderId}/options/`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function deliverOrderManually(data, orderStatus, orderId, token) {
    const formData = new FormData();
    formData.append("parcels", JSON.stringify(data.parcels));
    formData.append("status", orderStatus);
    formData.append("manual_delivery_note", data.manual_delivery_note);
    if (orderStatus === OrderStatusValues.DELIVERED) {
        formData.append("leave_picture", data.leave_picture);
        formData.append("leave_place", data.leave_place);
        formData.append("recipient_name", data.recipient_name);
        formData.append("recipient_house_number", data.recipient_house_number);
        formData.append("delivered_at", data.delivered_at.toISOString());
    } else if (orderStatus === OrderStatusValues.NOT_DELIVERED) {
        formData.append("leave_picture", data.leave_picture);
        formData.append("failure", data.failure);
        formData.append("delivered_at", data.delivered_at.toISOString());
    }
    return axios.post(`${Env.apiUrl}/api/orders/${orderId}/manual-delivery/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${token}`,
        },
    });
}

function deleteOrder(data, token) {
    return axios.delete(`${Env.apiUrl}/api/orders/${data.id}/`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function getDepotDetails(depotId, token) {
    return axios.get(`${Env.apiUrl}/api/routing/depot-details/?depot_id=${depotId}`, {headers: {Authorization: `Token ${token}`}});
}

function getReceivableOrders(depotId, token) {
    return axios.get(`${Env.apiUrl}/api/orders/receivable-orders/?depot=${depotId}`, {headers: {Authorization: `Token ${token}`}});
}

function getReceivableOrdersForSortation(token) {
    return axios.get(`${Env.apiUrl}/api/orders/receivable-orders-for-sortation/`, {headers: {Authorization: `Token ${token}`}});
}

function getParcelsForArrangingDeliveryPallets(sortationDepotId, depotId, token) {
    return axios.get(`${Env.apiUrl}/api/sortation-depots/${sortationDepotId}/parcels-for-arranging-delivery-pallets/?depot=${depotId}`, {
        headers: {Authorization: `Token ${token}`},
    });
}

function getParcelsForArrangingCollectionPallets(sortationDepotId, depotId, token) {
    return axios.get(`${Env.apiUrl}/api/sortation-depots/${sortationDepotId}/parcels-for-arranging-collection-pallets/?depot=${depotId}`, {
        headers: {Authorization: `Token ${token}`},
    });
}

function getSortationDepotDeliveryPallets(query, sortationDepotId, token) {
    return axios.get(`${Env.apiUrl}/api/sortation-depots/${sortationDepotId}/delivery-pallets/?${query}`, {
        headers: {Authorization: `Token ${token}`},
    });
}

function getSortationDepotCollectionPallets(query, sortationDepotId, token) {
    return axios.get(`${Env.apiUrl}/api/sortation-depots/${sortationDepotId}/collection-pallets/?${query}`, {
        headers: {Authorization: `Token ${token}`},
    });
}

function getOrdersforRouting(depotId, date, responseType, token) {
    const dateStr = moment(date).format("YYYY-MM-DD");
    return axios.get(`${Env.apiUrl}/api/orders/orders-for-routing/?depot=${depotId}&date=${dateStr}&response_type=${responseType}`, {
        data: {date: dateStr},
        headers: {Authorization: `Token ${token}`},
    });
}

function getTodayDeliveries(customerId, depotId, token) {
    return axios.get(`${Env.apiUrl}/api/orders/today-deliveries/?supplier=${customerId || ""}&depot=${depotId || ""}`, {
        headers: {Authorization: `Token ${token}`},
    });
}

function fetchDepotStockParcels(query, payload, token) {
    return axios.post(`${Env.apiUrl}/api/parcels/fetch-at-depot/?${query}`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function destroyParcel(reason, parcelId, token) {
    return axios.post(
        `${Env.apiUrl}/api/parcels/${parcelId}/destroy/`,
        {destroy_reason: reason},
        {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
}

function doNotHold(parcelId, token) {
    return axios.post(
        `${Env.apiUrl}/api/parcels/${parcelId}/do-not-hold/`,
        {},
        {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
}

function returnParcel(parcelId, token) {
    return axios.post(
        `${Env.apiUrl}/api/parcels/${parcelId}/return/`,
        {},
        {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
}

//------------------------- LABEL APIS -------------------------

function generateLabelPdf(order_id, token) {
    return axios.post(
        `${Env.apiUrl}/api/orders/${order_id}/label-pdf/`,
        {},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

//------------------------- STOCK APIS -------------------------

function receiveStock(data, depotId, token) {
    return axios.post(`${Env.apiUrl}/api/depots/${depotId}/receive-stock/`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function receiveStockAtSortationDepot(data, sortationDepotId, token) {
    return axios.post(`${Env.apiUrl}/api/sortation-depots/${sortationDepotId}/receive-stock/`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function receiveShipmentManually(payload, sortationDepotId, token) {
    return axios.post(`${Env.apiUrl}/api/sortation-depots/${sortationDepotId}/receive-shipment-manually/`, payload, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

//------------------------- Debrief APIS --------------------------------

function debriefOrder(action, orderId, token) {
    var payload = {
        action,
    };

    return axios.post(`${Env.apiUrl}/api/orders/${orderId}/debrief/`, payload, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function debriefParcel(action, parcelId, token) {
    var payload = {
        action,
    };

    return axios.post(`${Env.apiUrl}/api/parcels/${parcelId}/debrief/`, payload, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

// ------------------------ PALLETS APIS --------------------------

function submitDeliveryPalletScans(scans, palletId, token) {
    return axios.post(
        `${Env.apiUrl}/api/sortation-pallets/${palletId}/delivery-pallet-scans/`,
        {scans},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function submitCollectionPalletScans(scans, palletId, token) {
    return axios.post(
        `${Env.apiUrl}/api/sortation-pallets/${palletId}/collection-pallet-scans/`,
        {scans},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function createEmptyDeliveryPallet(data, token) {
    // const payload = {
    //     count: count,
    //     is_bag: isBag,
    //     depot: depotId,
    //     direction: PalletDirection.OUT,
    //     sortation_depot: sortationDepotId
    // };
    return axios.post(`${Env.apiUrl}/api/sortation-pallets/`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function createEmptyCollectionPallet(sortationDepotId, depotId, token) {
    const payload = {
        sortation_depot: sortationDepotId,
        depot: depotId,
        direction: PalletDirection.IN,
    };
    return axios.post(`${Env.apiUrl}/api/sortation-pallets/`, payload, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function deletePallet(palletId, token) {
    return axios.delete(`${Env.apiUrl}/api/sortation-pallets/${palletId}/`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function getDeliveryPallets(query, token) {
    return axios.get(`${Env.apiUrl}/api/sortation-pallets/?${query}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function fetchDeliveryPallets(query, payload, token) {
    return axios.post(`${Env.apiUrl}/api/sortation-pallets/fetch/?${query}&direction=${PalletDirection.OUT}`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function fetchCollectionPallets(query, payload, token) {
    return axios.post(`${Env.apiUrl}/api/sortation-pallets/fetch/?${query}&direction=${PalletDirection.IN}`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function exportDeliveryPallets(payload, token) {
    return axios.post(`${Env.apiUrl}/api/sortation-pallets/export-csv/?direction=${PalletDirection.OUT}`, payload, {
        responseType: "blob",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

//------------------------- WORK APIS --------------------------------

function createWork(routes, date, session_id, depotId, token) {
    return axios.post(
        `${Env.apiUrl}/api/depots/${depotId}/create-work/`,
        {
            date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
            routes: routes,
            depot: depotId,
            session_id
        },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function createEmptyRoute(date, departureTime, depotId, token) {
    return axios.post(
        `${Env.apiUrl}/api/depots/${depotId}/add-empty-route/`,
        {
            date: moment(date).format("DD/MM/YYYY"),
            departure_time: moment(departureTime).format("HH:mm"),
        },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function setRouteDepartureTime(departureTime, routeId, token) {
    return axios.post(
        `${Env.apiUrl}/api/routes/${routeId}/set-departure-time/`,
        {
            departure_time: moment(departureTime).format("HH:mm"),
        },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function getDepotWorks(depotId, dateFrom, dateTo, token) {
    return axios.get(
        `${Env.apiUrl}/api/depots/${depotId}/works/?date_from=${moment(dateFrom).format("YYYY-MM-DD")}&date_to=${moment(dateTo).format(
            "YYYY-MM-DD"
        )}&light=1`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function getWork(depotId, date, light, token) {
    return axios.get(`${Env.apiUrl}/api/depots/${depotId}/work/?depot=${depotId}&date=${moment(date).format("YYYY-MM-DD")}&light=${light ? 1 : 0}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

function submitAvilableDrivers(driverNums, depotId, token) {
    return axios.post(
        `${Env.apiUrl}/api/works/set-driver-nums/`,
        {
            driver_nums: driverNums,
            depot: depotId,
        },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

//------------------------- ROUTE APIS --------------------------------

function getRoutes(depotId, date, type, token) {
    return axios.get(`${Env.apiUrl}/api/routes/?type=${type}&depot=${depotId}&date=${moment(date).format("YYYY-MM-DD")}`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
}

function fetchRoutes(params, token) {
    return axios.get(`${Env.apiUrl}/api/routes/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
        params
    });
}

function getRoute(routeId, type, token) {
    return axios.get(`${Env.apiUrl}/api/routes/${routeId}/?type=${type}`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
}

function assignDriverToRoute(routeId, driverId, token) {
    return axios.post(
        `${Env.apiUrl}/api/routes/${routeId}/set-driver/`,
        {driver: driverId},
        {
            headers: {
                Authorization: `Token ${token}`,
            },
        }
    );
}

function deleteRoute(routeId, token) {
    return axios.delete(`${Env.apiUrl}/api/routes/${routeId}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
}

function makeRouteReady(routeId, token) {
    return axios.post(`${Env.apiUrl}/api/routes/${routeId}/make-is-ready/`, {
        is_ready: true
    }, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
}

function updateRoute(data, token) {
    return axios.patch(`${Env.apiUrl}/api/routes/${data.route_id}/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
}

//------------------------- ROUTE MANIFEST APIS --------------------------------

function generateRouteManifest(routeId, token) {
    return axios.post(
        `${Env.apiUrl}/api/routes/${routeId}/generate-manifest/`,
        {},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

// function generateReport(route_id, token) {
//     return axios.post(`${Env.apiUrl}/api/routes/${route_id}/report/`, {}, {
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Token ${token}`,
//         }
//     })
// }

// ------------------------ ROUTE API -----------------------

function debriefRoute(cashOnRoute, parcels, routeId, token) {
    return axios.post(
        `${Env.apiUrl}/api/routes/${routeId}/debrief/`,
        {
            cash_on_route: cashOnRoute || undefined,
            parcels,
        },
        {headers: {Authorization: `Token ${token}`}}
    );
}

// ------------------------ STOCK API -----------------------

function getDepotStockItems(query, token) {
    return axios.get(`${Env.apiUrl}/api/depot-stock-items/?${query}`, {headers: {Authorization: `Token ${token}`}});
}

function getDepotStock(depotId, token) {
    return axios.get(`${Env.apiUrl}/api/depot-stock/${depotId}/`, {headers: {Authorization: `Token ${token}`}});
}

function destroyDepotStock(amount, reason, productId, token) {
    return axios.post(
        `${Env.apiUrl}/api/depot-stock-items/${productId}/destroy/`,
        {amount, destroy_reason: reason},
        {headers: {Authorization: `Token ${token}`}}
    );
}

function returnDepotStock(amount, productId, token) {
    return axios.post(`${Env.apiUrl}/api/depot-stock-items/${productId}/return/`, {amount}, {headers: {Authorization: `Token ${token}`}});
}

function setUsableDepotStock(amount, productId, token) {
    return axios.post(
        `${Env.apiUrl}/api/depot-stock-items/${productId}/set-usable/`,
        {usable_amount: amount},
        {headers: {Authorization: `Token ${token}`}}
    );
}

function getMainStockItems(query, token) {
    return axios.get(`${Env.apiUrl}/api/main-stock-items/?${query}`, {headers: {Authorization: `Token ${token}`}});
}

function getInTransitStockItems(query, token) {
    return axios.get(`${Env.apiUrl}/api/in-transit-stock-items/?${query}`, {headers: {Authorization: `Token ${token}`}});
}

function getSortationDepotStockItems(query, token) {
    return axios.get(`${Env.apiUrl}/api/sortation-depot-stock-items/?${query}`, {headers: {Authorization: `Token ${token}`}});
}

function getSortationDepotScans(date, sortationDepotId, token) {
    return axios.get(`${Env.apiUrl}/api/sortation-depots/${sortationDepotId}/scans/?date=${moment(date).format("YYYY-MM-DD")}`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function exportSortationDepotScans(date, sortationDepotId, token) {
    return axios.get(`${Env.apiUrl}/api/sortation-depots/${sortationDepotId}/export-scans/?date=${moment(date).format("YYYY-MM-DD")}`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
        responseType: "blob",
    });
}

// ------------------------ SHIPMENT API -----------------------

function checkParcel(parcelId, token) {
    return axios.get(`${Env.apiUrl}/api/parcels/${parcelId}/`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

// --------------------- CSV Imports API --------------------

function fetchCSVImports(query, payload, token) {
    return axios.post(`${Env.apiUrl}/api/csv-imports/fetch/?${query}`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

// --------------------- Custom Declarations API --------------------

function fetchCustomDeclarations(query, payload, token) {
    return axios.post(`${Env.apiUrl}/api/custom-declarations/fetch/?${query}`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

// ------------------------ Pre Routing API -----------------------
function getOrderNumForPreRouting(token) {
    return axios.get(`${Env.apiUrl}/api/orders/orders-for-pre-routing/`, {headers: {Authorization: `Token ${token}`}});
}

function calculatePreroute(token) {
    return axios.post(`${Env.apiUrl}/api/pre-route/`, {}, {headers: {Authorization: `Token ${token}`}});
}

function getPreroutes(queryParams, token) {
    const queryStr = objectToQueryString(queryParams);
    return axios.get(`${Env.apiUrl}/api/preroutes/?${queryStr}`, {headers: {Authorization: `Token ${token}`}});
}

// ------------------------ Collections API -------------------------
function getDepotCollectionOverview(depotId, token) {
    return axios.get(`${Env.apiUrl}/api/depots/${depotId}/collection-overview/`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
}

// ------------------------ Customer Dashboard --------------------------

function getLatestOrderUpdates(len, customerId, depotId, token) {
    return axios.get(`${Env.apiUrl}/api/orders/latest-updates?l=${len}&supplier=${customerId || ""}&depot=${depotId || ""}`, {
        headers: {Authorization: `Token ${token}`},
    });
}

function getMonthlyOrderCount(customerId, depotId, year, token) {
    return axios.get(`${Env.apiUrl}/api/orders/monthly-count?supplier=${customerId || ""}&delivery_depots=${depotId || ""}&year=${year || ""}`, {
        headers: {Authorization: `Token ${token}`},
    });
}

function getDeliveriesByDate(days, customerId, depotId, token) {
    return axios.get(`${Env.apiUrl}/api/deliveries/deliveries-by-date/?days=${days}&supplier=${customerId || ""}&depot=${depotId || ""}`, {
        headers: {Authorization: `Token ${token}`},
    });
}

function getThirdPartyDeliveriesByDate(days, customerId, depotId, token) {
    return axios.get(`${Env.apiUrl}/api/deliveries/deliveries-by-date/?type=third_party&days=${days}&supplier=${customerId || ""}&depot=${depotId || ""}`, {
        headers: {Authorization: `Token ${token}`},
    });
}

function getRecentTimeinTransit(customerId, depotId, token) {
    return axios.get(`${Env.apiUrl}/api/orders/recent-time-in-transit/?supplier=${customerId || ""}&delivery_depots=${depotId || ""}`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function getHoldingInDeliveryDepot(customerId, depotId, token) {
    return axios.get(`${Env.apiUrl}/api/orders/holding-in-delivery-depot/?supplier=${customerId || ""}&delivery_depots=${depotId || ""}`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function getTodayWorkForDashboard(token) {
    return axios.get(`${Env.apiUrl}/api/orders/work-today/`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function getMissortedOrdersNum(customerId, depotId, token) {
    return axios.get(`${Env.apiUrl}/api/orders/missorted/?supplier=${customerId || ""}&delivery_depots=${depotId || ""}`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function exportRoutesSummary(token) {
    return axios.post(`${Env.apiUrl}/api/orders/export-work-today/`, {}, {
        responseType: "blob",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

// ------------------------ Addresses --------------------------

function fetchDeliveryAddresses(query, payload, token) {
    return axios.post(`${Env.apiUrl}/api/addresses/fetch/?${query}`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function approveDeliveryAddress(location, validationArea, addressId, token) {
    return axios.post(
        `${Env.apiUrl}/api/addresses/${addressId}/approve/`,
        {
            location: location,
            validation_area: validationArea,
        },
        {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
}

function changeOrderLocation(location, orderId, token) {
    return axios.post(
        `${Env.apiUrl}/api/orders/${orderId}/change-location/`,
        {
            location: location,
        },
        {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
}

function changeOrderDeliveryDate(deliveryDate, orderId, token) {
    return axios.post(
        `${Env.apiUrl}/api/orders/${orderId}/change-delivery-date/`,
        {
            delivery_date: deliveryDate,
        },
        {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
}

// ------------------------ Addresses --------------------------

function getAddressRequests(queryParams, token) {
    const queryStr = objectToQueryString(queryParams);
    return axios.get(`${Env.apiUrl}/api/address-requests/?${queryStr}`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function setRequestedAddress(address, addressId, token) {
    return axios.post(
        `${Env.apiUrl}/api/address-requests/${addressId}/set-requested-address/`,
        {address},
        {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
}

function approveAddressRequest(addressId, token) {
    return axios.post(
        `${Env.apiUrl}/api/address-requests/${addressId}/approve/`,
        {},
        {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
}

function setDelayAdjustment(outcodeFrom, outcodeTo, percentage, token) {
    return axios.post(
        `${Env.apiUrl}/api/set-delay-adjustment/`,
        {
            outcode_from: outcodeFrom,
            outcode_to: outcodeTo,
            percentage,
        },
        {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
}

// --------------------- Order Manifests --------------------

function fetchOrderManifests(query, payload, token) {
    const queryStr = objectToQueryString(query);
    return axios.post(`${Env.apiUrl}/api/shipments/order-manifests/fetch/?${queryStr}`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function getOrderManifestDetails(orderManifestId, token) {
    return axios.get(`${Env.apiUrl}/api/shipments/order-manifests/${orderManifestId}/`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function deleteOrderManifest(orderManifestId, token) {
    return axios.delete(`${Env.apiUrl}/api/shipments/order-manifests/${orderManifestId}/`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

// --------------------- Collection Points --------------------


// --------------------- Return Orders --------------------

function fetchReturnOrders(query, payload, token) {
    return axios.post(`${Env.apiUrl}/api/return-orders/fetch/?${query}`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function finishReturnOrders(orderIds, token) {
    return axios.post(`${Env.apiUrl}/api/return-orders/finish-return/`, {orders: orderIds}, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}


function changeReturnOrderStatus(status, orderId, token) {
    return axios.patch(
        `${Env.apiUrl}/api/return-orders/${orderId}/change-status/`,
        {status},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function getNonLocatedOrdersNum(token) {
    return axios.get(
        `${Env.apiUrl}/api/orders/get-non-located-num/`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function getOrderStatusLogs(orderId, token) {
    return axios.get(
        `${Env.apiUrl}/api/orders/${orderId}/status-logs/`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}


function startRouteManually(payload, routeId, token) {
    return axios.post(
        `${Env.apiUrl}/api/routes/${routeId}/start-manually/`, payload,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function fetchBulkLabels(query, token) {
    return axios.get(`${Env.apiUrl}/api/bulk-labels/fetch/?${query}`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    });
}

function fetchBulkStatus(params, token) {
    return axios.get(`${Env.apiUrl}/api/bulk-status-update/`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
        params
    });
}

function fetchBulkStatusHistory(id, params, token) {
    return axios.get(`${Env.apiUrl}/api/bulk-status-update/${id}/get-orders/`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
        params
    });
}

function bulkStatusRevert(id, token) {
    return axios.post(`${Env.apiUrl}/api/bulk-status-update/${id}/revert-changes/`, {}, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        }
    });
}

function bulkStatusChange(payload, token) {
    return axios.post(`${Env.apiUrl}/api/bulk-status-update/`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
        }
    });
}

function regenerateBulkLabelPdf(bulkLabelId, token) {
    return axios.post(
        `${Env.apiUrl}/api/bulk-labels/${bulkLabelId}/regenerate/`,
        {},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}

function orderKeepAtDepot(data, token) {
    return axios.post(
        `${Env.apiUrl}/api/orders/order-keep-at-depot/`, data,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        }
    );
}


function fetchAndRepoShipments(data, params, token) {
    return axios.post(`${Env.apiUrl}/api/reports/reliability/get-andrepo-shipments/`, data, {
        responseType: params.export ? "blob" : "",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
        params
    });
}


function thirdPartyOrderLabelPrintOnDedicated(orderID, token) {
    return axios.post(`${Env.apiUrl}/api/orders/${orderID}/third-party-label-print/`, {}, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        }
    });
}

function thirdPartyOrderLabelPrintOnLocal(orderID, token) {
    return axios.get(`${Env.apiUrl}/api/orders/${orderID}/third-party-label-get/`, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        }
    });
}

function manualThirdPartyOrderCreate(orderID, token) {
    return axios.post(`${Env.apiUrl}/api/orders/${orderID}/create-third-party-order/`, {}, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        }
    });
}

function saveAMZLShipmentID(payload, token) {
    return axios.post(`${Env.apiUrl}/api/third-party-orders/shipment-update/`, payload, {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        }
    });
}

function leavePlaceForNSPOrder(orderID, payload, token) {
    return axios.post(`${Env.apiUrl}/api/orders/${orderID}/select-leave-place-for-nsp-order/`, payload, {
        params: {
            token
        }
    });
}

function locateNonLocateOrders(token) {
    return axios.post(`${Env.apiUrl}/api/orders/locate-non-located-orders/`, {}, {
        headers: {
            Authorization: `Token ${token}`,
        }
    });
}

function fetchNLCollections(params, token) {
    return axios.get(`${Env.apiUrl}/api/nl-collections/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
        params
    });
}

function fetchOutstandingNLCollections(params, token) {
    return axios.get(`${Env.apiUrl}/api/nl-collections/outstanding-nl-collections/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
        params
    });
}

function fetchNLCollectionsMapMarkers(params, token) {
    return axios.get(`${Env.apiUrl}/api/nl-collections/nl-collections-for-map/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
        params
    });
}