import auth from './auth';
import orders from './orders';
import notification from './notification';
import ThemeOptions from './ThemeOptions';

export default {
    auth,
    orders,
    notification,
    ThemeOptions
};