import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import {COLORS} from "../constants";


const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const FormReactSelect = (props) => {
    const {
        selectKey,
        name,
        id,
        onChange,
        className,
        value,
        label,
        errorMsg,
        options,
        optionLabel,
        optionValue,
        invalid,
        isDisabled,
        isLoading,
        isClearable,
        isMulti,
        isRtl,
        isSearchable,
        isOptionDisabled,
        disabledColor,
        defaultValue,
        placeholder,
        onBlur,
        inputProps
    } = props
    // const { t } = useTranslation();

    const displayBlock = {display: "block"};

    const customStyles = {
        control: (provided, state) => {
            return !invalid
                ? {
                    ...provided,
                    backgroundColor: state.isDisabled ? disabledColor || "hsl(0,0%,95%)" : "white",
                    // height: "calc(1.2em + 0.75rem + 2px)",
                    minHeight: "calc(1.2em + 0.75rem + 2px)",
                }
                : {
                    ...provided,
                    borderColor: COLORS.danger,
                    // height: "calc(1.2em + 0.75rem + 2px)",
                    minHeight: "calc(1.2em + 0.75rem + 2px)",
                };
        },
        dropdownIndicator: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: ".25em",
            paddingRight: ".25em",
        }),
        placeholder: (base) => ({
            ...base,
            color: "#adb5bd",
        }),
        menu: (styles) => ({...styles, zIndex: 1000}),
        clearIndicator: (styles, state) => {
            return {
                ...styles,
                paddingTop: 0, paddingBottom: 0
            };
        },
    };
    return (
        <>
            <div className="w-100">
                <Select
                    key={selectKey}
                    styles={customStyles}
                    id={id}
                    placeholder={placeholder}
                    className={className}
                    classNamePrefix="select"
                    defaultValue={defaultValue}
                    value={value}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isMulti={isMulti || false}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    inputProps={inputProps}
                    name={name || null}
                    options={options}
                    onBlur={onBlur || (() => {
                    })}
                    onChange={onChange}
                    isOptionDisabled={isOptionDisabled}
                    getOptionValue={(option) => option[optionValue || "value"]}
                    getOptionLabel={(option) => option[optionLabel || "label"]}
                    formatGroupLabel={(data) => (
                        <div style={groupStyles}>
                            <span>{data.label}</span>
                            <span style={groupBadgeStyles}>{data.options.length}</span>
                        </div>
                    )}
                />
            </div>
            {invalid && (
                <div className="invalid-feedback" style={displayBlock}>
                    {errorMsg}
                </div>
            )}
        </>
    );
};

FormReactSelect.defaultProps = {
    className: "form-control-label",
    optionValue: "value",
    optionLabel: "label",
    isSearchable: true
};

FormReactSelect.propTypes = {
    selectKey: PropTypes.string,
    id: PropTypes.string,
    errorMsg: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    optionValue: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    disabledColor: PropTypes.string,
    defaultValue: PropTypes.object,
    invalid: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isClearable: PropTypes.bool,
    isMulti: PropTypes.bool,
    isRtl: PropTypes.bool,
    isSearchable: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    isOptionDisabled: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default FormReactSelect;
